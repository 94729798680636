import React from 'react';
import classes from './ComingSoon.module.css';

const ComingSoon = () => {
  return (
    <div className={classes.coming_soon_parent}>
        <h2>COMING SOON</h2>
    </div>
  )
}

export default ComingSoon