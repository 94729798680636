import React from 'react';
import Help from '../../../component/help/Help';

const CustomerSupport = () => {
  return (
    <div>
      <Help />
    </div>
  )
}

export default CustomerSupport