import React from 'react';
import classes from './Container7.module.css';

import flagg1 from '../../flags/flagg1.png';
import flagg2 from '../../flags/flagg2.png';
import flagg3 from '../../flags/flagg3.png';
import flagg4 from '../../flags/flagg4.png';
import flagg5 from '../../flags/flagg5.png';
import flagg6 from '../../flags/flagg6.png';
import flagg7 from '../../flags/flagg7.png';
import flagg8 from '../../flags/flagg8.png';
import flagg9 from '../../flags/flagg9.png';
import flagg10 from '../../flags/flagg10.png';
import flagg11 from '../../flags/flagg11.png';
import flagg12 from '../../flags/flagg12.png';
import flagg13 from '../../flags/flagg13.png';
import flagg14 from '../../flags/flagg14.png';
import flagg15 from '../../flags/flagg15.png';
import flagg16 from '../../flags/flagg16.png';
import flagg17 from '../../flags/flagg17.png';
import flagg18 from '../../flags/flagg18.png';
import flagg19 from '../../flags/flagg19.png';
import flagg20 from '../../flags/flagg20.png';
import flagg21 from '../../flags/flagg21.png';
import flagg22 from '../../flags/flagg22.png';
import flagg23 from '../../flags/flagg23.png';
import flagg24 from '../../flags/flagg24.png';
import flagg25 from '../../flags/flagg25.png';
import flagg26 from '../../flags/flagg26.png';
import flagg27 from '../../flags/flagg27.png';
import flagg28 from '../../flags/flagg28.png';
import flagg29 from '../../flags/flagg29.png';
import flagg30 from '../../flags/flagg30.png';
import flagg31 from '../../flags/flagg31.png';
import flagg32 from '../../flags/flagg32.png';
import flagg33 from '../../flags/flagg33.png';
import flagg34 from '../../flags/flagg34.png';
import flagg35 from '../../flags/flagg35.png';
import flagg36 from '../../flags/flagg36.png';
import flagg37 from '../../flags/flagg37.png';
import flagg38 from '../../flags/flagg38.png';
import flagg39 from '../../flags/flagg39.png';
import flagg40 from '../../flags/flagg40.png';

const Container7 = () => {
    const images = [
        flagg1,
        flagg2,
        flagg3,
        flagg4,
        flagg5,
        flagg6,
        flagg7,
        flagg8,
        flagg9,
        flagg10,
        flagg11,
        flagg12,
        flagg13,
        flagg14,
        flagg15,
        flagg16,
        flagg17,
        flagg18,
        flagg19,
        flagg20,
        flagg21,
        flagg22,
        flagg23,
        flagg24,
        flagg25,
        flagg26,
        flagg27,
        flagg28,
        flagg29,
        flagg30,
        flagg31,
        flagg32,
        flagg33,
        flagg34,
        flagg35,
        flagg36,
        flagg37,
        flagg38,
        flagg39,
        flagg40,

    ];
  return (
    <div className={classes.model_container7_parent}>
        <div className={classes.model_container7_img}>
        {images.map((image, index) => (
            <img 
            key={index} 
            src={image} 
            alt={`Image ${index + 1}`} 
            style={{ width: '43px', height: '43px' }} 
            />
        ))}
      </div>
      <h2>120+ Countries,  50+ Currencies</h2>
      <p>Choose from the secure bank transfers via SEPA or SWIFT, or go through the traditional old-school cash way, it is in your hands.</p>
    
      <div className={classes.model_container7_img}>
        {images.map((image, index) => (
            <img 
            key={index} 
            src={image} 
            alt={`Image ${index + 1}`} 
            style={{ width: '43px', height: '43px' }} 
            />
        ))}
      </div>
    </div>
  )
}

export default Container7;