import React from 'react';
import classes from './Structure4.module.css';
import InternationalBtn from '../../component/international-btn/InternationalBtn';

const Structure4 = ({title1, title2, desc, btn}) => {
  return (
    <div className={classes.structure4_parent}>
      <h4 className={classes.structure4_title1}>{title1}</h4>
      <h1 className={classes.structure4_title2}>
        {title2.split('<br />').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </h1>
      <p className={classes.structure4_desc}>
        {desc.split('<br />').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
      <InternationalBtn title={btn} />
    </div>
  )
}

export default Structure4;