import React from 'react';
import classes from './Container5.module.css';

const Container5 = ({title, img, desc, dates }) => {
  return (
    <div className={classes.model_container5_parent}>
        <h3>{title}</h3>
        <img src={img} alt="img" />
        <p>{desc}</p>
        <label>{dates}</label>
    </div>
  )
}

export default Container5;