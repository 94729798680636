import React from 'react';
import classes from './Help.module.css';
import InternationalBtn from '../international-btn/InternationalBtn';

const Help = () => {
  return (
    <div className={classes.help_parent}>
        <div className={classes.help_child_1}>
            <h2>WE ARE HERE TO HELP</h2>
        </div>
        <div className={classes.help_child_2}>
            <h2 className={classes.align_center}>Help center</h2>
            <p>The fastest way to get answers is through our Help center in our app, go into the search bar on the transfers or apps screen and<br />
            find all your questions.</p>
            <div className={classes.margin_bottom}></div>
            <h2 className={classes.align_center}>I lost access to my account/card</h2>
            <h4>If you’ve lost access to your account and need emergency protection, follow these steps:</h4>
            <p>1. When logging in, tap ‘I lost access to my phone number’</p>
            <p>2. Tap Forgot Passcode and go through the procedures to reset your passcode</p>
            <p>If you still can’t access your account, contact us through our support mobile number. The Rival team will never call you directly and ask for access to your accounts usernames and passwords.</p>
            <div className={classes.margin_bottom}></div>
            <h2 className={classes.align_center}>I lost access to my account/card</h2>
            <h4>If you’ve lost access to your account and need emergency protection, follow these steps:</h4>
            <p>1. When logging in, tap ‘I lost access to my phone number’</p>
            <p>2. Tap Forgot Passcode and go through the procedures to reset your passcode</p>
            <p>If you still can’t access your account, contact us through our support mobile number. The Rival team will never call you directly and ask for access to your accounts usernames and passwords.</p>
            <div className={classes.margin_bottom}></div>
            <h2 className={classes.align_center}>I lost access to my phone number or Rival app</h2>
            <p className={classes.align_center}>The fastest way to get answers is through our Help center in our app, go into the search<br/>bar on the transfers or apps screen and find all your questions.</p>
            {/* skdfjlsdfj */}
            <div className={classes.margin_bottom}></div>
            <h2 className={classes.align_center}>I'VE NOTICED SOME SUSPICIOUS<br />ACTIVITY WITH MY ACCOUNT</h2>
            <div className={classes.button_center}><InternationalBtn title="Report it" /></div>
            <h3 className={classes.align_center}>Frequently Asked Questions</h3>
            {/* sldkjflsdkjflsk */}
        </div>
    </div>
  )
}

export default Help;