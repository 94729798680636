import React from 'react';
import Content1 from '../../compo/home/content1/Content1';
import Content2 from '../../compo/home/content2/Content2';
import Content3 from '../../compo/home/content3/Content3';
import Content4 from '../../compo/home/content4/Content4';
import Content5 from '../../compo/home/content5/Content5';
// import Content6 from '../../components/home/content6/Content6';

const Home = () => {
  return (
    <div>
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        {/* <Content6 /> */}
    </div>
  )
}

export default Home;