import React, {useState} from 'react';
// import Structure1 from '../../../component/structure-1/Structure1';
import Structure3 from '../../../component/structure-3/Structure3';
import Structure4 from '../../../component/structure-4/Structure4';
import Structure5 from '../../../component/structure-5/Structure5';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';

const ComparePlans = () => {
  const [currentImage, setCurrentImage] = useState('red');
  const [activeButton, setActiveButton] = useState('red');

  const imageData = {
    red: {text: 'Standard', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Frame-1991425096-1-1536x501.png'},
    black: {text: 'Plus', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Frame-1991425097-1-1536x501.png'},
    gold: {text: 'Pro', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Frame-1991425095-1-1536x501.png'}
  }
  
  const handleButtonClick = (imageName) => {
    setCurrentImage(imageName);
    setActiveButton(imageName);
  };
  return (
    <div>
      <Structure4
        title1="Compare Plans"
        title2="CHOOSE YOUR<br /> PERFECT PLAN"
        desc="3 plans, 8 colors and much more to come. We have built something for all
        sorts of members. Pick your plan which suits you to save money on fees
        and unlock stacks of unexpected benefits. Paid Plan Terms apply."
        // desc="3 plans, 8 colors and much more to come. We have built something for all<br />
        // sorts of members. Pick your plan which suits you to save money on fees<br />
        // and unlock stacks of unexpected benefits. Paid Plan Terms apply."
        btn="Pick Your Plan"
      />
      
      <Structure5
        title="YOUR card, your choice"
        desc="Choose a card that will match your benefits, your needs and your style."
        imageData={imageData}
        currentImage={currentImage}
        activeButton={activeButton}
        onButtonClick={handleButtonClick}
      />
      {/* <Structure1
        title="YOUR card, your choice"
        desc="Choose a card that will match your benefits, your needs and your style."
        btn="Get The App"
        image={iphone}

        propsParent={propsParent1}
        propsTitle={propsTitle1}
        propsDesc={propsDesc1}
        propsImg={propsImg1}
      /> */}
      <Structure4
        title2="TAKE YOUR PICK"
        desc="No surprises, just fair and honest pricing. Compare plans with ease,
        and choose the one that works for you."       
        btn="Pick Your Plan"
      />
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default ComparePlans;