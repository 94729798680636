import React from 'react';
import classes from './Countries.module.css';

const Countries = ({data, title, desc}) => {
  return (
    <div className={classes.countries_parent}>
    <h1>{title}</h1>
    <p>{desc}</p>
    {Object.keys(data).map((continent) => (
        <div key={continent} className={classes.continent_container}>
            <h2>{continent}</h2>
            <div className={classes.countries_grid}>
                {data[continent].map((country) => (
                    <div key={country.name} className={classes.country_item}>
                        <img src={country.flag} alt={country.name} className="country-flag" />
                        <label>{country.name}</label>
                    </div>
                ))}
            </div>
        </div>
    ))}

    </div>
  )
}

export default Countries