import React from 'react';
import classes from './Container4.module.css';

const Container4 = ({text, img}) => {
  return (
    <div className={classes.model_container4_parent}>
        <div className={classes.model_container4_left}>
            <p>{text}</p>
        </div>
        <div className={classes.model_container4_right}>
            <img src={img} alt="lskdjfl" />
        </div>
    </div>
  )
}

export default Container4;