import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure1 from '../../../component/structure-1/Structure1';
import Structure3 from '../../../component/structure-3/Structure3';
import dollar from '../../../assets/phone-dollar.png';
import bitcoin from '../../../assets/bit-coin.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';

const Referrals = () => {
  // first style
  const propsTitle1 = {
    color: '#246BFD'
  }
  const propsDesc = {
    color: '#00000099'
  }
  const propsDescc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '800px',
  }
  // second style
  const propsParent_2 = {

  }
  const propsTitle_2 = {

  }
  const propsDesc_2 = {
    color: '#00000099'
  }
  const propsImg_2 = {
    marginTop: '-4rem',
    marginBottom: '-3px'
  }
  return (
    <div>
      <Structure2
        ptitle="Referral"
        title="Invite Your<br />Friends"
        desc="Make up to 50$ for every invited family or friend.<br />
        From Elie to Sara to Ahmad, you can make money while<br />
        you support them to grow with Rival."
        desc3="Referral’s T&C’s apply"
        btn="Start Your Journey"
        image={dollar}

        propsTitle1={propsTitle1}
        propsDesc={propsDesc}
        propsDescc={propsDescc}
        propsImage={propsImage}
      />
      
      <Structure1
        title="STACKED WITH UP TO 10%<br />CASHBACK"
        desc="Book your Stay or Experience wiht Rival, get up to 10% cashback depending on your plan. Oh, and don't forget our exclusive 
        rates and zero booking fees charged by us. Stays and Experiences T&Cs apply."
        btn="Get Started"
        image={bitcoin}

        propsParent={propsParent_2}
        propsTitle={propsTitle_2}
        propsDesc={propsDesc_2}
        propsImg={propsImg_2}
      />
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Referrals