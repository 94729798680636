





import React, { useState, useRef } from 'react';
import classes from './Content4.module.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import image1 from '../../../assets/transfer-cards.png';
import image2 from '../../../assets/transfer-phone.png';
import image3 from '../../../assets/transfer-world.png';
import image4 from '../../../assets/grow-your-wealth.png';
import image5 from '../../../assets/no-phone-no-problem.png';
import cross from '../../../assets/cross-new.png';

import sixcard from '../../../assets/model-6cards.png';
import yellowphone from '../../../assets/model-yellow-3blue-rival-phone.png'
import bluephone from '../../../assets/model-3blueplus-phone.png';
import goldphone from '../../../assets/model-rival-goldsingle-phone.png';
import bluesinglephone from '../../../assets/model-single-bluephone.png';
import support from '../../../assets/model-single-support-phone.png';
import watches from '../../../assets/model-watches.png';

import ContentTop from '../../../model/content-top/ContentTop';
import Container1 from '../../../model/container1/Container1';
import Container2 from '../../../model/container2/Container2';
import Container3 from '../../../model/container3/Container3';
import Container4 from '../../../model/container4/Container4';
import Container5 from '../../../model/container5/Container5';
import Container6 from '../../../model/container6/Container6';
import Container7 from '../../../model/container7/Container7';

const Content4 = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modalContent, setModalContent] = useState(null); // To track which content to show in the modal
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    const newPosition = Math.max(scrollPosition - 420, 0);
    setScrollPosition(newPosition);
    carouselRef.current.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    const newPosition = scrollPosition + 420;
    setScrollPosition(newPosition);
    carouselRef.current.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  };

  // const openModal = (content) => {
  //   setModalContent(content);
  // };

  // const closeModal = () => {
  //   setModalContent(null)
  // };

  const openModal = (content) => {
    setModalContent(content);
    document.body.style.overflow = 'hidden'; // Disable scroll on body when modal is open
  };
  
  const closeModal = () => {
    setModalContent(null);
    document.body.style.overflow = ''; // Re-enable scroll on body when modal is closed
  };
  

  return (
    <div className={classes.home_content4_parent}>
      <h2>Get to know Rival.</h2>
      <div className={classes.carousel_container}>
        <FaArrowLeft size={40} color="white" className={`${classes.arrow} ${classes.left_arrow}`} onClick={scrollLeft} />
        <div className={classes.carousel} ref={carouselRef}>
          <img src={image1} alt="Image 1" onClick={() => openModal('content1')} />
          <img src={image2} alt="Image 2" onClick={() => openModal('content2')} />
          <img src={image3} alt="Image 3" onClick={() => openModal('content3')} />
          <img src={image4} alt="Image 4" onClick={() => openModal('content4')} />
          <img src={image5} alt="Image 5" onClick={() => openModal('content5')} />
        </div>
        <FaArrowRight size={40} color="#fff" className={`${classes.arrow} ${classes.right_arrow}`} onClick={scrollRight} />
      </div>

      {/* Modal */}
      {modalContent && (
        <div className={classes.modal} onClick={closeModal}>
          <div className={classes.modal_content} onClick={(e) => e.stopPropagation()}>
            {/* <button className={classes.close_button} onClick={closeModal}>Close</button> */}
            <img src={cross} className={classes.close_button} onClick={closeModal} />
            <div className={classes.modal_body}>
              {modalContent === 'content1' && (
                <>
                <ContentTop
                  title1="Transfer"
                  title2="Send money anywhere, any place, any time"
                />
                <Container1
                  text="<span>At Rival, we don't just move money—we empower your financial freedom.</span> Whether you need to send money across the street or around the globe, our platform lets you transfer money seamlessly, locally and internationally. <span>No hidden fees. No delays.</span> Just instant, secure transfers you can count on. With 24/7 support and multi-currency options, <span>Rival puts you in full control of your finances. Fast. Reliable. Yours.<span>"
                  img={sixcard}
                />
                <Container2
                  text="Our app is built to make your financial transfers as easy as possible. With an intuitive design, you can send money locally or internationally in just a few taps. Manage your transfers, track transactions, and receive funds—all from the palm of your hand."
                  img={bluephone}
                />
                <Container7 />
                </>
              )}
              {modalContent === 'content2' && (
                <>
                <ContentTop
                  title1="Cards"
                  title2="Your cards, your vibes"
                />
                <Container1
                  text="<span>At Rival, we don't just move money—we empower your financial freedom.</span> Whether you need to send money across the street or around the globe, our platform lets you transfer money seamlessly, locally and internationally. <span>No hidden fees. No delays.</span> Just instant, secure transfers you can count on. With 24/7 support and multi-currency options, <span>Rival puts you in full control of your finances. Fast. Reliable. Yours.<span>"
                  img={sixcard}
                />
                <Container2
                  text="Our cards puts you in full control. Instantly access card details, <span>add funds,</span> or <span>freeze</span> your card with a tap. Manage everything on your terms—right from your phone."
                  img={yellowphone}
                />
                <Container3
                   img={goldphone}
                   text="With Apple Pay, get ready to start spending instantly in-store and online as soon as your card is issued. There's no need to wait for a physical card to be printed or worry about losing it."
                />
                </>
              )}
              {modalContent === 'content3' && (
                <>
                <ContentTop
                  title1="Support"
                  title2="Having issues?"
                />
                <Container4
                  img={bluesinglephone}
                  text="Our FAQ section is an available  resource that offers quick answers to common questions on topics like account management, payments, and troubleshooting. New FAQs are regularly added to keep users informed and supported."
                />
                <Container5
                  title="Not enough?"
                  img={support}
                  desc="We provide customer support for all users"
                  dates="24/7 365"
                />
                </>
              )}
              {modalContent === 'content4' && (
                <>
                <ContentTop
                  title1="Invest"
                  title2="Grow your wealth"
                />
                <Container4
                  img={bluesinglephone}
                  text="Our FAQ section is an available  resource that offers quick answers to common questions on topics like account management, payments, and troubleshooting. New FAQs are regularly added to keep users informed and supported."
                />
                <Container5
                  title="Not enough?"
                  img={support}
                  desc="We provide customer support for all users"
                  dates="24/7 365"
                />
                </>
              )}
              {modalContent === 'content5' && (
                <>
                <ContentTop
                  title1="Cards"
                  title2="Your cards, your vibes"
                />
                <Container6
                  text="Our cards puts you in full control. Instantly access card details, add funds, or freeze your card with a tap. Manage everything on your terms—right from your phone."
                  img={watches}
                />
                <Container2
                  text="Our cards puts you in full control. Instantly access card details, <span>add funds,</span> or <span>freeze</span> your card with a tap. Manage everything on your terms—right from your phone."
                  img={yellowphone}
                />
                <Container3
                   img={goldphone}
                   text="With Apple Pay, get ready to start spending instantly in-store and online as soon as your card is issued. There's no need to wait for a physical card to be printed or worry about losing it."
                />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content4;

