

import React, { useState, useRef } from 'react';
import classes from './Content1.module.css';
import { FaPlay, FaPause } from 'react-icons/fa';

const Content1 = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  }

  return (
    <div className={classes.home_container1_parent}>
      <video
        controls={false}
        autoPlay
        loop
        muted
        ref={videoRef}
        className={classes.video}
      >
        <source src="https://www.apple.com/105/media/us/home/2024/d7d4fab7-f7ed-4e5f-857a-570e8dcecae4/anim/hero/large.mp4" type="video/mp4" />
      </video>
      <div className={classes.content}>
        <p>Next generation finance is here – delivering unmatched speed, security, and innovation to elevate your financial experience.</p>
        <button>Watch an event</button>
        <div className={classes.playPauseIcon} onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </div>
      </div>
    </div>
  )
}

export default Content1;
