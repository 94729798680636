import React from 'react';
import classes from './Container2.module.css';

const Container2 = ({text, img}) => {
  return (
    <div className={classes.model_container2_parent}>
        <p dangerouslySetInnerHTML={{ __html: text }} />
        <img src={img} alt="img" />
    </div>
  )
}

export default Container2;