import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure1 from '../../../component/structure-1/Structure1';
import fivecards from '../../../assets/five-cards.png';
import crypto from '../../../assets/crypto-coins.png';
import yellow from '../../../assets/phone-yellow.png';
import phone from '../../../assets/phone-transiction.png';

const Cards = () => {
  // first style
  const propsParent = {
    background: '#000000'
  }
  const propsTitle1 = {
    color: '#246BFD',
  }
  const propsTitle2 = {
    color: '#ffffff'
  }
  const propsDesc = {
    color: 'rgba(255, 255, 255, 0.6)'
  }
  const propsImage = {
    maxWidth: '477px'
  }
  // second style
  const propsDesc2 = {
    color: '#00000099'
  }
  const propsImg2 = {
    maxWidth: '700px',
    marginBottom: '3rem'
  }
  // third style
  const propsParent3 = {
    background: '#0c0c0c',
    paddingBottom: '4rem'
  }
  const propsTitle3 = {
    color: '#ffffff'
  }
  const propsDesc3 = {
    color: '#9e9e9e'
  }
  const propsImg3 = {
    maxWidth: '650px'
  }
  // fourth style
  const propsDesc4 = {
    color: '#00000099'

  }
  const propsImg4 = {
    maxWidth: '450px'
  }
  return (
    <div>
      <Structure2
        ptitle="cards"
        title="Choose Your Vibe.<br />Choose Your Card."
        desc="Don’t limit yourself to one card – get two, three or even 10<br />cards. 
        Mix and match with your besties or even match your card to<br />your outfit. 
        Always be ahead of the trend! Sky is the limit… or your<br />balance is."
        // desc="Don’t limit yourself to one card – get two, three or even 10 cards. 
        // Mix and<br />match with your besties or even match your card to your outfit. 
        // Always<br />be ahead of the trend! Sky is the limit… or your balance is."
        btn="Get my card"
        image={fivecards}
        propsParent={propsParent}
        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <Structure1
        title="THE REAL THING"
        desc="Why go physical? Because you like the weight of a card in your hand. 
        And for you, getting out cash is king. Delivery fees apply."
        btn="Get my card"
        // image={crypto}
        type="video"
        videos="https://rival.finance/wp-content/uploads/2024/01/CoinsAnimation730310.mp4"

        propsDesc={propsDesc2}
        propsImg={propsImg2}
      />
      <Structure1
        title="INSTANT GRATIFICATION"
        desc="Link your virtual card to your Apple or Google Pay wallet to start paying immediately."
        btn="Try it out"
        image={yellow}

        propsParent={propsParent3}
        propsTitle={propsTitle3}
        propsDesc={propsDesc3}
        propsImg={propsImg3}
      />
      <Structure2
        title="Transactions"
        desc="Always stay up to date with your payments, instantly.<br />
        Manage limit and increase your spending with our simplified<br />
        transaction management accessibility."
        btn="Get my card"
        // image={phone}
        image="https://rival.finance/wp-content/uploads/2024/01/Group-1171278433-1445x1536.png"

        // propsTitle2={propsTitle2}
        propsDesc={propsDesc4}
        propsImage={propsImg4}
      />
    </div>
  )
}

export default Cards