import React from 'react';
import classes from './Structure5.module.css';

const Structure5 = ({imageData, currentImage, activeButton, onButtonClick, title, desc}) => {
  return (
    <div className={classes.structure5_parent}>
      <h1>
        {title}
      </h1>
      <p>
        {desc}
      </p>
      <div className={classes.structure5_buttons}>
      {Object.keys(imageData).map((key) => (
                <button
                    key={key}
                    onClick={() => onButtonClick(key)}
                    style={{
                        color: activeButton === key ? 'black' : 'white',
                        backgroundColor: activeButton === key ? '#fff' : '#131313',
                    }}
                >
                    {imageData[key].text}
                </button>
            ))}
            </div>
      <div style={{ marginTop: '2rem' }}>
                <img 
                    src={imageData[currentImage].imageUrl} 
                    alt={currentImage} 
                    style={{ width: '100%', maxWidth: '500px', height: 'auto' }} 
                />
            </div>
    </div>
  )
}

export default Structure5;