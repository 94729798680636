import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import coins from '../../../assets/crypto-coins.png';
import classes from './StocksAndMetals.module.css';

const StocksAndMetals = () => {
  const propsTitle1 = {
    color: '#246BFD'
  }
  const propsDesc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '630px'
  }
  return (
    <div>
    <Structure2
      ptitle="Stocks and Metals"
      title="CAPITALIZE ON<br />OPPORTUNITY"
      desc="Enjoy access to more than 2000+ stocks and metals at your<br />fingertips. Start trading from as low as 10$"
      desc2="Enjoy access to more than 2000+ stocks and metals at your<br />fingertips. Start trading from as low as 10$"
      btn="Get Started"
      // image={coins}
      type="video"
      videos="https://rival.finance/wp-content/uploads/2024/01/CoinsAnimation730310.mp4"

      propsTitle1={propsTitle1}
      propsDesc={propsDesc}
      propsImage={propsImage}
    />
    <Structure2
      title="Your risk, Your<br />Reward"
      desc="Choose your adventure: Cryptocurrency for the bold, stocks for<br />the steadfast, or metals for the cautious. Every style has its shine."
      btn="Start Your Journey"
      image="https://rival.finance/wp-content/uploads/2024/01/Group-1171278429-1-1-1536x1062.png"

      propsTitle1={propsTitle1}
      propsDesc={propsDesc}
      propsImage={propsImage}
    />
    <div className={classes.stocks_content}>
      <div className={classes.stocks_title1}>0%</div>
      <h1 className={classes.stocks_title2}>COMMISION FREE TRADING</h1>
      <p className={classes.stocks_desc}>
        Buy and sell stocks, crypto and metals while benefitting from commission-free
        trades within your monthly allowance.
      </p>
    </div>
    </div>
  )
}

export default StocksAndMetals