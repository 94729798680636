import React from 'react';
import classes from './Container6.module.css';

const Container6 = ({text, img}) => {
  return (
    <div className={classes.model_container6_parent}>
        <p>{text}</p>
        <img src={img} alt="img" />
    </div>
  )
}

export default Container6;