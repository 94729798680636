import React from 'react';
import classes from './Content2.module.css';
import rivaltitle from '../../../assets/rival-title.png';
import rivalphone from '../../../assets/rival-title-phone.png';

const Content2 = () => {
  return (
    <div className={classes.home_content2_parent}>
      <h2>Empowering your money</h2>
      <img className={classes.image1} src={rivaltitle} alt="rival-title" />
      <img className={classes.image2} src={rivalphone} alt="rival-phone" />
    </div>
  )
}

export default Content2;