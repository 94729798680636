

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Header.module.css';
import Rival from '../../assets/r-logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <img src={Rival} alt="R" />
      </Link>
      <nav className={`${classes.nav} ${isOpen ? classes.open : ''}`}>
        <ul>
          <li onClick={toggleMenu}>
            <Link to="/?page_id=1">Personal</Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/?page_id=2">Business</Link>
          </li>
          <li onClick={toggleMenu}>
            <Link to="/?page_id=3">Company</Link>
          </li>
        </ul>
        <FaTimes className={classes.closeIcon} onClick={toggleMenu} />
      </nav>
      <FaBars className={classes.menuIcon} onClick={toggleMenu} />
    </header>
  );
};

export default Header;

