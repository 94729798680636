import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import mobilecoins from '../../../assets/mobile-coins.png';
import transfer from '../../../assets/transfer.png';
import classes from './Crypto.module.css';

const cryptoData = [
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
  {
    imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png",
    title: "Solana"
  },
]

const Crypto = () => {
  // style first
  const propsTitle1 = {
    color: '#246BFD'
  }
  const propsDesc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '530px'
  }
  // style second
  const propsTitle2_2 = {
    color: '#ffffff',
  }
  const propsDesc_2 = {
    color: '#FFFFFF99'
  }
  const propsImage_2 = {
    maxWidth: '417px',
    order: '1'
  }
  const propsParent_2 = {
    background: '#131313',
    display: 'flex'
  }
  const propsLeft_2 = {
    order: '2'
  }

  return (
    <div>
    <Structure2
      ptitle="Crypto"
      title="CRYPTO<br />CRAZE"
      desc="Grow your crypto portfolio at your convenience.<br />T&C’s apply."
      btn="Get Started"
      image={mobilecoins}

      propsTitle1={propsTitle1}
      propsDesc={propsDesc}
      propsImage={propsImage}
    />
    <div className={classes.crypto_parent}>
      <h1>SO MANY WAYS TO SEND MONEY ABROAD</h1>
      <div className={classes.crypto_box_container}>
        {cryptoData.map((item, index) => (
        <div key={index} className={classes.crypto_box}>
          <img src={item.imgSrc} alt="lksjdf" />
          <label>{item.title}</label>
        </div>
        ))}
      </div>
    </div>
    <Structure2
      ptitle="Crypto"
      title="TRANSFER YOUR<br />TOKENS"
      desc="Exchange your USDT to USD anytime throughout the day. Contact<br />
      customer support for more information. T&C’s apply."
      btn="Get the app"
      image={transfer}

      propsParent={propsParent_2}
      propsTitle1={propsTitle1}
      propsTitle2={propsTitle2_2}
      propsDesc={propsDesc_2}
      propsImage={propsImage_2}
      propsLeft={propsLeft_2}
    />
    </div>
  )
}

export default Crypto