

import React from 'react';
import classes from './Structure2.module.css';
import InternationalBtn from '../international-btn/InternationalBtn';

const Structure2 = ({ type, videos, ptitle, title, title2, desc, desc2="", desc3, btn, image, propsParent, propsDescc, propsTitle3, propsLeft, propsTitle1, propsTitle2, propsDesc, propsImage }) => {
  return (
    <div className={classes.structure2_parent} style={propsParent}>
      <div className={classes.structure2_left} style={propsLeft}>
        <div className={classes.structure2_title1} style={propsTitle1}>{ptitle}</div>
        
        <div className={classes.structure2_title2} style={propsTitle2}>
          {title.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        
        <div className={classes.structure2_title2} style={propsTitle3}>
          {title2}
        </div>

        <p className={classes.structure2_desc} style={propsDesc}>
          {desc.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <p className={classes.structure2_desc} style={propsDesc}>
          {desc2.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}

        </p>
        <p className={classes.structure2_descc} style={propsDescc}>{desc3}</p>
        <InternationalBtn title={btn} />
      </div>
      <div className={classes.structure2_right} style={propsImage}>
        {/* <img src={image} alt="structure2-img" /> */}
            {type==='video'? (
              <video width="100%" autoPlay loop muted>
                <source src={videos} type="video/mp4" />
              </video>
            ):(
              <img src={image} alt="image" width="100%" />
            )}
      </div>
    </div>
  )
}

export default Structure2;
