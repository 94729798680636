import React from 'react';
import classes from './Transfers.module.css';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure3 from '../../../component/structure-3/Structure3';
import Countries from '../../../component/countries/Countries';
import world from '../../../assets/world-money.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';
import bank from '../../../assets/bank-transfers.png'
import wallet from '../../../assets/wallet-transfers.png';
import peer from '../../../assets/peer-tranfers.png';
import card from '../../../assets/card-transfers.png';

const Transfers = () => {
  const data = {
      Asia: [
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
          { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
      ],
      Europe: [
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
        { name: 'China', flag: 'https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/brazil-flag-round-circle-icon.png' },
      ],
  };



  const propsTitle1 = {
    color: '#246BFD'

  }
  const propsDesc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '538px'
  }
  return (
    <div>
      <Structure2
        ptitle="International Transfers"
        title="SEND MONEY<br />ANYWHERE,<br />GLOBALLY"
        desc="Send money to 120+ countries and with 50+ currencies,<br />your gateway to global inclusion."
        btn="Start Sending Money"
        image={world}

        propsTitle1={propsTitle1}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <Countries
        title="120+ Countries. 50+ Currencies"
        desc="From Beirut to Montreal, and almost all countries in between, send money however you like. The world is in between your fingertips, literally…"
        data={data}
      />

      <div className={classes.transfers_parent}>
        <h1>SO MANY WAYS TO SEND<br />MONEY ABROAD</h1>
        <div className={classes.transfers_child}>
          <div className={classes.transfers_child_boxes}>
            <img src={bank} alt="bank" />
            <h3 style={{marginTop: '4rem'}}>Bank transfers</h3>
            <p>Send and receive money through bank accounts (SWIFT & SEPA) accessibility. T&C’s apply.</p>
          </div>
          <div className={classes.transfers_child_boxes}>
            <img src={card} alt="card" />
            <h3 style={{marginTop: '2rem'}}>Card Transfers</h3>
            <p>Transfer money in between Rival Card users</p>
          </div>
          <div className={classes.transfers_child_boxes}>
            <img src={peer} alt="peer" />
            <h3>Peer transfers</h3>
            <p>Pay your Rival friends instantly, no more excuses.</p>
          </div>
          <div className={classes.transfers_child_boxes}>
            <img src={wallet} alt="wallet" />
            <h3>Wallet transfers</h3>
            <p>Send money to local and international wallets in a split second, we are everywhere.</p>
          </div>
        </div>
      </div>

      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Transfers