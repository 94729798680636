import React from 'react';
import classes from './Container3.module.css';

const Container3 = ({img, text}) => {
  return (
    <div className={classes.model_container3_parent}>
        <div className={classes.model_container3_left}>
            <img src={img} alt="lskdjfl" />
        </div>
        <div className={classes.model_container3_right}>
            <p>{text}</p>
        </div>
    </div>
  )
}

export default Container3;