import React from 'react';
import CodeConductChild from '../../../component/code-conduct-child/CodeConductChild';
import classes from './CodeOfConduct.module.css';

const detail1 = {
  title: 'VISION, VALUES AND CONDUCT',
  title2: 'WE EXPECT ALL OUR PEOPLE TO UPHOLD THE CONDUCT RULES SET BY OUR REGULATORS:',
  desc: 'Our vision is to build the world’s first financial super app and to do this we need to assemble and retain a brilliant Dream Team, who are happy and fulfilled at work. Our culture is true to our values; where everyone is accountable for their actions, are encouraged to speak up, strive for excellence and always do the right thing.',
  options: [
    'Act with integrity',
    'Treat customers fairly',
    'Act with due skill, care and diligence',
    'Be open and co-operative with regulators',
    'Observe proper standards of market conduct'
  ],
  image: 'https://rival.finance/wp-content/uploads/2024/02/Rectangle-42157.png'
}
const detail2 = {
  title: 'OUR APPROACH TO CONDUCT',
  title2: 'KEY GUIDANCE FOR RIVAL EMPLOYEES',
  desc: 'Our reputation is founded on the trust and integrity of our people and the people we do business with. At Rival, we expect all our people to uphold the highest ethical and legal standards, as defined in our global and local policies and procedures. We uphold our reputation by always doing the right thing.',
  options: [
    'Policies and procedures',
    'Employee handbook',
    'Speak Up portal',
    'Your line manager',
    'Risk and Compliance service desk',
    'Legal service desk'
  ],
  image: 'https://rival.finance/wp-content/uploads/2024/02/Rectangle-42156.png'
}
const detail3 = {
  title: 'OUR CUSTOMERS',
  title2: 'WHAT GOOD LOOKS LIKE:',
  desc: 'Our customers always come first. Every product and service we build, starts with their needs, not ours. Because when we truly understand our customers, we deliver outstanding products that work harder for them and their money.',
  options: [
    'We always put the customer at the heart of what we do',
    'We listen to and consider their needs',
    'We design our products to be easy to use',
    'We talk to them like humans, not robots',
    'We remember that customers are real people with their own unique circumstances',
    'We resolve their complaints and queries promptly and fairly',
    'We respect and safeguard their personal data and privacy rights',
    'We avoid any conflicts between our personal interests and those of Rival and its customers'
  ],
  image: 'https://rival.finance/wp-content/uploads/2024/02/Rectangle-42156-1.png'
}
const detail4 = {
  title: 'RISK MANAGEMENT',
  title2: 'WHAT GOOD LOOKS LIKE:',
  desc: 'Rival’s Enterprise Risk Management Framework sets out our approach to identifying, measuring, monitoring, mitigating and reporting risks. It sets out the roles and responsibilities of everyone involved and asks all of us to be accountable for our actions.',
  options: [
    'We always put the customer at the heart of what we do',
    'We listen to and consider their needs',
    'We design our products to be easy to use',
    'We help our customers get the most from our products and services',
    'We remember that customers are real people with their own unique circumstances',
    'We resolve their complaints and queries promptly and fairly',
    'We respect and safeguard their personal data and privacy rights',
    'We avoid any conflicts between our personal interests and those of Rival and its customers'
  ],
  image: 'https://rival.finance/wp-content/uploads/2024/02/Rectangle-42156-2.png'
}

const CodeOfConduct = () => {
  return (
    <div className={classes.code_conduct_parent}>
      <div className={classes.code_conduct1}>
        <h1>CODE OF CONDUCT</h1>
        <p className={classes.align_center}>We’re on a mission to reinvent the way the world does money.<br />
        To make it easier, more useful and more rewarding for everyone. We all have a<br />
        part to play in building the world’s first financial super app. This Code of<br />
        Conduct is designed to make sure we win the right way.</p>
      </div>
      <div className={classes.code_conduct2}>
        <div className={classes.code_conduct2_left}>
          <h1>MESSAGE FROM KHALED BALTAJI, CEO</h1>
          <p>We believe that brilliant people, working together in an honest, high-achieving culture, 
            are the key to delivering the amazing products and services our customers love. And we know 
            that our behaviour towards one another is critical to that success. Our Code of Conduct sets 
            out the principles that must guide the ways we behave and the decisions we make. Yes, it’s 
            a little formal, but it has to be to ensure that we all act with integrity and in the best 
            interests of our customers, people, communities and stakeholders, at all times.
          </p>
        </div>
        <div className={classes.code_conduct2_right}>
          <img src="https://rival.finance/wp-content/uploads/2024/01/Group-1171278399-1510x1536.png" alt="waves" />
        </div>
      </div>
      <CodeConductChild detail={detail1} />
      <CodeConductChild detail={detail2} />
      <CodeConductChild detail={detail3} />
      <CodeConductChild detail={detail4} />
    </div>
  )
}

export default CodeOfConduct;