import React from 'react';
import twocards from '../../../assets/two-cards.png';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure1 from '../../../component/structure-1/Structure1';
import Structure3 from '../../../component/structure-3/Structure3';
import Structure4 from '../../../component/structure-4/Structure4';
import coins from '../../../assets/mobile-coins.png';
import flag from '../../../assets/flags.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';
import threecards from '../../../assets/3-cards.png'

const Plus = () => {
  // style first
  const propsParent = {
  background: 'linear-gradient(209.52deg, #21FFBC -12.05%, #2613FF 93.97%)'
  }
  const propsTitle1 = {
    color: '#fff'
  }
  const propsTitle2 = {
    color: '#fff'
  }
  const propsDesc = {
    color: '#fff'
  }
  const propsDescc = {
    color: '#fff'
  }
  const propsImage = {
    maxWidth: '507px'
  }
  // style second
  const propsDesc_2 = {
    color: '#00000099'
  }
  const propsImg_2 = {
    maxWidth: '600px',
    marginBottom: '2rem'
  }
  // style third
  const propsParent_3 = {
    background: '#131313'
  }
  const propsDesc_3 = {
    color: '#FFFFFF99'
  }
  const propsImage_3 = {
    maxWidth: '430px'
  }
  // style fourth
  const propsTitle1_4 = {
    color: '#246BFD'
  }
  const propsDesc_4 = {
    color: '#141414'
  }
  const propsDescc_4 = {
    color: '#00000099'
  }
  const propsImage_4 = {
    maxWidth: '414px'
  }
  // style fifth
  const propsParent_5 = {
    background: '#f4f4f4'
  }
  const propsDesc_5 = {
    color: '#00000099'
  }
  return (
    <div>
      <Structure2
        ptitle="Plus $9.99/month"
        title="PLUS POWER<br />UNLOCKED"
        desc="Enjoy less fees, higher free comission trades, stronger yields and<br />no minimum deposits. Your control."
        desc3="Paid Plan Terms Apply."
        btn="Get Plus"
        image={twocards}

        propsParent={propsParent}
        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsDescc={propsDescc}
        propsImage={propsImage}
      />
      <Structure1
        title="MAKE IT TRULY YOURS"
        desc="Customize your card with your intitals, her initials or even your dogs initials, 
        add different icons and emojis. It’s your choice."
        btn="Coming Soon"
        image={threecards}

        propsDesc={propsDesc_2}
        propsImg={propsImg_2}
      />
      <Structure2
        title="Crypto<br />Craze"
        desc="Invest in all sorts of cryptocurrencies, from Bitcoin<br />to Solana to Shiba Inu and many more…"
        desc3="Capital is at Risk. T&C’s apply."
        btn="Get Plus"
        image={coins}

        propsParent={propsParent_3}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc_3}
        propsDescc={propsDescc}
        propsImage={propsImage_3}
      />
      <Structure2
        ptitle="Referral"
        title="Send Money<br />Locally and<br />Globally"
        desc="At your conviniece send money through Bank Transfers to over 160+ countries or 
        send<br />them locally through WHISH/OMT and all other local providers."
        desc3="Referral’s T&C’s apply"
        btn="Send Now"
        image={flag}

        propsTitle1={propsTitle1_4}
        propsDesc={propsDesc_4}
        propsDescc={propsDescc_4}
        propsImage={propsImage_4}
      />
      
      <Structure4
        title2="Lost Between What To Choose?"
        desc="Compare our plans and see what fits you most."
        btn="Learn more"

        propsParent={propsParent_5}
        propsDesc={propsDesc_5}
      />
      
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Plus