import React from 'react';
import Structure1 from '../../../component/structure-1/Structure1';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure3 from '../../../component/structure-3/Structure3';
import threecards from '../../../assets/3cards.png';
import phones from '../../../assets/3phone-top.png';
import fivecards from '../../../assets/5cards.png';
import lock from '../../../assets/lock1.png';
import crypto from '../../../assets/crypto-coins.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';

const Standard = () => {
  // first style
  const propsTitle1 = {
    color: '#246BFD'
  }
  const propsDesc = {
    color: '#00000099'

  }
  const propsDescc = {
    color: '#00000099'
  }
  const propsImage = {
    maxWidth: '400px'
  }
  // second style
  const propsDesc_2 = {
    color: '#00000099'
  }
  const propsImg_2 = {
    maxWidth: '600px',
    marginBottom: '-3px'
  }
  // third style
  const propsImg_3 = {
    maxWidth: '312px',
    marginBottom: '3rem'
  }
  // fourth style
  const propsImg_4 = {
    maxWidth: '653px'
  }
  return (
    <div>
      <Structure2
        ptitle="Standard $5.99/month"
        title="Get Started<br />For Free"
        desc="No need for subscriptions no need for commitments,<br />open an account and access the world."
        desc3="Paid Plan Terms Apply."
        btn="GetStandard"
        image={threecards}

        propsTitle1={propsTitle1}
        propsDesc={propsDesc}
        propsDescc={propsDescc}
        propsImage={propsImage}
      />
      <Structure1
        title="GET, SET, READY, RIVAL"
        desc="No more Dollar, Fresh, or signing contracts to open an account, 3 minutes and you can access the world."
        btn="Learn more"
        image={phones}

        propsDesc={propsDesc_2}
        propsImg={propsImg_2}
      />
      <Structure1
        title="Tokenization, Achieved"
        desc="Add you card to Apple Pay or view it through our app, you can begin paying immediately."
        btn="Try It Out"
        image={fivecards}

        propsDesc={propsDesc_2}
        propsImg={propsImg_2}
      />
      <Structure1
        title="Lock Em Up"
        desc="Begin earning high yield’s through our savings plan’s with maximum security. Your money
        is safe in global institutions across European and GCC nations."
        btn="Start Saving Now"
        // image={lock}
        type="video"
        videos="https://rival.finance/wp-content/uploads/2024/01/LockUnlockWithWhiteBG.mp4"

        propsDesc={propsDesc_2}
        propsImg={propsImg_3}
      />
      <Structure1
        title="Diversify Your Future"
        desc="From Apple to Tesla to Gold, choose from more than 2000+ stocks and commodities
        across the globe. Your stable future is in your hands. Capital at risk. T&Cs apply"
        btn="Get the app"
        // image={crypto}
        type="video"
        videos="https://rival.finance/wp-content/uploads/2024/01/CoinsAnimation730310.mp4"

        propsDesc={propsDesc_2}
        propsImg={propsImg_4}
      />
      
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Standard