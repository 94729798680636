import React from 'react';
import classes from './ContentTop.module.css';

const ContentTop = ({title1, title2}) => {
  return (
    <div className={classes.model_contenttop_parent}>
        <label>{title1}</label>
        <h3>{title2}</h3>
    </div>
  )
}

export default ContentTop;