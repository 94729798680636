import React from 'react';
import { useLocation } from 'react-router-dom';

import Home from './pages/homes/Home';
import Personal from './pages/personal-container/personal/Personal';
import Business from './pages/business/Business';
import Company from './pages/company-container/company/Company';
import Current from './pages/personal-container/current/Current';
import Savings from './pages/personal-container/savings/Savings';
import Cards from './pages/personal-container/cards/Cards';
import Transfers from './pages/personal-container/transfers/Transfers';
import Crypto from './pages/personal-container/crypto/Crypto';
import StocksAndMetals from './pages/personal-container/stocks&metals/StocksAndMetals';
import CashBack from './pages/personal-container/cashback/CashBack';
import Referrals from './pages/personal-container/referrals/Referrals';
import Standard from './pages/personal-container/standard/Standard';
import Plus from './pages/personal-container/plus/Plus';
import Pro from './pages/personal-container/pro/Pro';
import ComparePlans from './pages/personal-container/compare-plans/ComparePlans';
import CustomerSupport from './pages/personal-container/customer-support/CustomerSupport'
import Terms from './pages/personal-container/terms/Terms';
import Contact from './pages/company-container/contact/Contact';
import Security from './pages/company-container/security/Security';
import CodeOfConduct from './pages/company-container/code-of-conduct/CodeOfConduct';
import NewsAndMedia from './pages/company-container/news&media/NewsAndMedia'

import Footer from './component/footer/Footer';
import Header from './component/header/Header';

const App = () => {
  const location = useLocation();

  const getPageComponent = () => {
    const searchParams = new URLSearchParams(location.search);
    const pageId = searchParams.get('page_id');

    switch (pageId) {
      case '1':
        return <Personal />;
      case '2':
        return <Business />;
      case '3':
        return <Company />;
      case '4':
        return <Current />;
      case '5':
        return <Savings />;
      case '6':
        return <Cards />;
      case '7':
        return <Transfers />;
      case '8':
        return <Crypto />;
      case '9':
        return <StocksAndMetals />;
      case '10':
        return <CashBack />;
      case '11':
        return <Referrals />;
      case '12':
        return <Standard />;
      case '13':
        return <Plus />;
      case '14':
        return <Pro />;
      case '15':
        return <ComparePlans />
      case '16':
        return <CustomerSupport />;
      case '17':
        return <Terms />;
      case '19':
        return <Contact />;
      case '20':
        return <Security />;
      case '21':
        return <CodeOfConduct />;
      case '22':
        return <NewsAndMedia /> 
      default:
        return <Home />;
    }
  };
  return (
    <React.Fragment>
      <Header />
      {getPageComponent()}
      <Footer />
    </React.Fragment>
  )
}

export default App