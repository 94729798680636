import React from 'react';
import classes from './Terms.module.css';
import InternationalBtn from '../../../component/international-btn/InternationalBtn';

const Terms = () => {
  return (
    <div className={classes.terms_parent}>
      <div className={classes.terms_child_1}>
        <h2>TERM' & CONDITION'S</h2>
      </div>
      <TermsChild text="Last updated 10/05/2022" btn="Rival Term's & and Condition's" />
      <TermsChild text="Last updated 10/05/2022" btn="Rival Privacy Policy" />
    </div>
  )
}

export default Terms;

const TermsChild = ({btn, text}) => {
  return (
    <div className={classes.terms_child_parent}>
      <InternationalBtn title={btn} />
      <label>{text}</label>
    </div>
  )
}