import React from 'react';
import classes from './CodeConductChild.module.css';
import bluetick from '../../assets/blue-tick.png';

const CodeConductChild = ({detail}) => {
  return(
    <div className={classes.conduct_child_parent}>
      <div className={classes.conduct_child_first}>
        <h1 className={classes.allign_center}>{detail.title}</h1>
        <p className={classes.allign_center}>
          {detail.desc.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className={classes.conduct_child_second}>
        <div className={classes.conduct_child_second_left}>
          <h1>{detail.title2}</h1>
          {detail.options.map((option, index) => (
          <div className={classes.second_left_child}>
            <img src={bluetick} alt="blue-tick" />
            <h4 key={index}>{option}</h4>
          </div>
          ))}
        </div>
        <div className={classes.conduct_child_second_right}>
          <img src={detail.image} alt="payment" />
        </div>
      </div>
    </div>
  )
}

export default CodeConductChild;