import React from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure3 from '../../../component/structure-3/Structure3';
import classes from './Savings.module.css';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';
import lock1 from '../../../assets/lock1.png';
import lock2 from '../../../assets/lock2.png';
import lock3 from '../../../assets/lock3.png';


const Savings = () => {
  // styles
  const propsTitle1 = {
    color: '#246BFD',
    textAllign: 'left'
  }
  const propsTitle2 = {
    color: '#030303',
    textAllign: 'left'
  }
  const propsDesc = {
    color: '#00000099',
    textAllign: 'left'

  }
  const propsImage = {
    maxWidth: '320px'
  }
  return (
    <div className={classes.savings_parent}>
      <Structure2
        ptitle="Savings"
        title="MAKE YOUR<br />MONEY MAKE<br />MONEY"
        desc="Waiting’s overrated. Grow your savings faster with interest paid<br />daily at excellent rates, with instant access."
        btn="Start Saving"
        // image={lock1}
        type="video"
        videos="https://rival.finance/wp-content/uploads/2024/01/LockUnlockWithWhiteBG.mp4"
        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <div className={classes.savings_content1}>
        <h2>GET INTEREST PAID DAY AFTER<br />DAY AFTER DAY</h2>
        <p>Open an account in seconds, and see your money grow, compound, and<br />increase. Every single day.</p>
        <p>This interest rate is variable, and your returns may vary depending on your plan.</p>
        <h1>up to 3.84%*</h1>
      </div>
      <div className={classes.savings_content2}>
        <h2>GROW YOUR MONEY IN<br />3 CURRENCIES</h2>
        <p>Open an account in seconds, and see your money grow, compound,<br />and increase. Every single day.</p>
        <div className={classes.savings_content2_child}>
          <div className={classes.savings_content2_child_left}>
            <img src={lock2} alt="locked" />
            <h3>Locked plans</h3>
            <p>Plans starting as low as $100 Tailored periods and Yields Locked Plans of at least 180 days</p>
          </div>
          <div className={classes.savings_content2_child_right}>
            <img src={lock3} alt="unlocked" />
            <h3>Unlocked plans</h3>
            <p>Savings that can be withdraw anytime Paid Monthly Grow your savings starting with as low as 1$</p>
          </div>
        </div>
      </div>
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Savings;