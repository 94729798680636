import React from 'react';
import classes from './Company.module.css';
import InternationalBtn from '../../../component/international-btn/InternationalBtn';
import wave from '../../../assets/waves.png';
import trophy from '../../../assets/trophy.png';

const Company = () => {
  return (
    <div className={classes.company_parent}>
    <div className={classes.company_container1}>
        <h4>About Us</h4>
        <h1>EMPOWER YOUR MONEY</h1>
        <p>From your everyday spending, to planning for your future<br />
        with savings and investments, Rival helps you get<br />
        more from your money.</p>
        <InternationalBtn title="Try Rival Now" />
    </div>
    <div className={classes.company_container2}>
      <div className={classes.company_container2_child}>

        <span>20+</span>
        <label>Years in Investing / Payment Services</label>
      </div>
      <div className={classes.company_container2_child}>
        <span>500</span>
        <label>Types Of Transfers</label>
      </div>
      <div className={classes.company_container2_child}>
        <span>226</span>
        <label>Countries and regions supported</label>
      </div>
      <div className={classes.company_container2_child}>
        <span>8</span>
        <label>Partnered Banks and Financial Institutions</label>
      </div>
    </div>
    <div className={classes.company_container3}>
      <div className={classes.container3_left}>
        <h1>OUR MISSION: Empower Your Money</h1>
        <p>We are living in a time of worry, fraud and uncertain futures. Rival is 
          looking to build a standard reliable and efficient model who seek stability 
          across their money.</p>
        <p>That’s why we are here. Rival exists to remove all the day to day issues that users suffer from.</p>
        <p>We are building a platform so simple yet so advanced, that what ever you will need in Finance is available in one spot.</p>
        <p>Our goal is for everyone to Empower their money through our services, in just a few taps. We are not there, we are still far from it, however it is in our vision.</p>
      </div>
      <div className={classes.container3_right}>
        <img src={wave} alt="wave" />
      </div>
    </div>
    <div className={classes.company_container4}>
      <h2>GROWTH AND FUTURE PLANS</h2>
      <div className={classes.container4_boxes}>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>Coming Soon</h3>
          <p>-Coming Soon</p>
        </div>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>Coming Soon</h3>
          <p>-Coming Soon</p>
        </div>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>Coming Soon</h3>
          <p>-Coming Soon</p>
        </div>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>Coming Soon</h3>
          <p>-Coming Soon</p>
        </div>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>Coming Soon</h3>
          <p>-Coming Soon</p>
        </div>
        <div className={classes.container4_box}>
          <img src={trophy} alt="award" />
          <h3>2024 Q2</h3>
          <p>-Rival Finance Launch</p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Company;