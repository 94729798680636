import React from 'react';
import classes from './Structure3.module.css';
import InternationalBtn from '../international-btn/InternationalBtn';

const Structure3 = ({title, desc, btn, img1, img2, img3}) => {
  return (
    <div className={classes.structure3_parent}>
        <h2 className={classes.structure3_title}>{title}</h2>
        <p className={classes.structure3_desc}>
        {desc.split('<br />').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
        </p>
        <InternationalBtn title={btn} />
        <div className={classes.structure3_img}>
            <img src={img1} alt="img1" />
            <img src={img2} alt="img2" />
            <img src={img3} alt="img3" />
        </div>
    </div>
  )
}

export default Structure3;