import React, {useState} from 'react';
import Structure2 from '../../../component/structure-2/Structure2';
import Structure1 from '../../../component/structure-1/Structure1';
import Structure3 from '../../../component/structure-3/Structure3';
import Structure5 from '../../../component/structure-5/Structure5';
import redcards from '../../../assets/red-cards.png';
import coin from '../../../assets/bit-coin.png';
import lock from '../../../assets/lock1.png';
import coins from '../../../assets/crypto-coins.png';
import image7 from '../../../assets/image7.png';
import image8 from '../../../assets/image8.png';
import image10 from '../../../assets/image10.png';

const Pro = () => {
  const [currentImage, setCurrentImage] = useState('red');
  const [activeButton, setActiveButton] = useState('red');

  const imageData = {
    red: {text: 'Red', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Group-1171278487-1.png'},
    black: {text: 'Black', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Group-1171278642-1.png'},
    gold: {text: 'Gold', imageUrl: 'https://rival.finance/wp-content/uploads/2024/01/Group-1171278643-1.png'}
  }
  
  const handleButtonClick = (imageName) => {
    setCurrentImage(imageName);
    setActiveButton(imageName);
  };


  // styles first{
  const propsParent = {
  background: 'linear-gradient(209.52deg, #21FFBC -12.05%, #2613FF 93.97%)'
  }
  const propsTitle1 = {
    color: '#fff'
  }
  const propsTitle2 = {
    color: '#fff'
  }
  const propsDesc = {
    color: '#fff'
  }
  const propsImage = {
    maxWidth: '340px'
  }
  // styles second
  const propsDesc_2 = {
    color: '#00000099',
  }
  const propsImg_2 = {
    marginTop: '-4rem',
    marginBottom: '-3px'
  }
  // styles third
  const propsParent_3 = {
    background: '#141414'
  }
  const propsTitle2_3 = {
    color: '#fff'
  }
  const propsDesc_3 = {
    color: '#9e9e9e'
  }
  const propsDescc_3 = {
    color: '#fff'
  }
  const propsImage_3 = {
    maxWidth: '300px'
  }
  // styles fourth
  const propsDesc_4 = {
    color: "#00000099"
  }
  const propsImg_4 = {
    maxWidth: '630px'
  }
  return (
    <div>
      <Structure2
        ptitle="Plus $9.99/month"
        title="TAILORED FOR<br />PRO​"
        desc="From extra perks to pro goods to high cashbacks, now you<br />
        can flex your lifestyle. Paid Plan Terms Apply."
        btn="Get Pro"
        image={redcards}

        propsParent={propsParent}
        propsTitle1={propsTitle1}
        propsTitle2={propsTitle2}
        propsDesc={propsDesc}
        propsImage={propsImage}
      />
      <Structure5
        title="What's YOUR TYPE?"
        desc="Choose from an exclusive red or a sleek black card. Your spending has gotten spicy. Apple Pay available"
        imageData={imageData}
        currentImage={currentImage}
        activeButton={activeButton}
        onButtonClick={handleButtonClick}
      />
      <Structure1
        title="PERFECT PARTNERS, FOR WHATEVER<br />YOU’RE INTO"
        desc="We’ve got partners, worth £550 in annual retail value, that can take your life to the next level — find Freeletics for fitness, snooze tips on Sleep Cycle, and find your own match with Tinder… all included with Premium."
        btn="Get Premium"
        image={coin}

        propsDesc={propsDesc_2}
        propsImg={propsImg_2}
      />
      <Structure2
        title="Earn Up to 3.87%<br />ARR"
        desc="Compared to 1.4% AER on Standard. Yield is paid monthly on your savings,<br />
        you can withdraw them at any time. Locked savings have even higher yields and they<br />
        can be withdrawn through semi-yearly or yearly depending on your choice. Your savings <br />
        are protected by International and Global financial institutions across the GCC and European regions.<br />
        T&C’s apply."
        btn="Get Premium"
        image={lock}

        propsParent={propsParent_3}
        propsTitle2={propsTitle2_3}
        propsDesc={propsDesc_3}
        propsDescc={propsDescc_3}
        propsImage={propsImage_3}
      />
      <Structure1
        title="Diversify Your Future"
        desc="Virtual, Physical, online or in-store. Use it any time, any where, on
        demand or at a scheduled time. Your money, your vibe, your Rival
        card. T&Cs apply"
        btn="Get The App"
        // image={coins}
        type="video"
        videos="https://rival.finance/wp-content/uploads/2024/01/CoinsAnimation730310.mp4"

        propsDesc={propsDesc_4}
        propsImg={propsImg_4}
      />
      
      <Structure3
        title='SAFETY ALWAYS'
        desc='Advanced fraud protection, 24/7 monitory and all<br />
        day customer support availability.'
        btn='Learn more'
        img1={image7}
        img2={image8}
        img3={image10}
      />
    </div>
  )
}

export default Pro