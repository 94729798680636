import React from 'react';
import classes from './Container1.module.css';

const Container1 = ({text, img}) => {
  return (
    <div className={classes.model_container1_parent}>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      <img src={img} alt="img" />
    </div>
  )
}

export default Container1;