import React from 'react';
import classes from './NewsAndMedia.module.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const NewsAndMedia = () => {
  return (
    <div>
      <div className={classes.content1}>
        <h4>What's in the news?</h4>
        <h1>NEWS AND MEDIA</h1>
        <p>lease write to us at info@rival.finance if you have a media enquiry. We’ll do
        our best to get back to you as soon as possible.</p>
        <div className={classes.buttons}>
          <button>Get Started</button>
          <button>Image for Media</button>
        </div>
      </div>
      <div className={classes.content2}>
        <h1>NEWS AND MEDIA</h1>
        <h4>No Data found!</h4>
      </div>
      <div className={classes.content3}>
        <h1>LATEST NEWS</h1>
        <p>For media queries get in touch at media@rival.com</p>
        <div className={classes.footer_social_icons}>
      <div className={classes.footer_icon_container}>
        <FaFacebookF className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaTwitter className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaInstagram className={classes.footer_icon} />
      </div>
      <div className={classes.footer_icon_container}>
        <FaLinkedinIn className={classes.footer_icon} />
      </div>
    </div>
      </div>
    </div>
  )
}

export default NewsAndMedia;