import React from 'react';
import ComingSoon from '../../component/coming-soon/ComingSoon';

const Business = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  )
}

export default Business