import React from 'react';
import classes from './Structure1.module.css';
import InternationalBtn from '../../component/international-btn/InternationalBtn';
// import image from '../../assets/'
const coins = '../../assets/coin-video.mp4';

const Structure1 = ({title, descc, desc="", btn, type ,  image, videos, propsParent, propsTitle, propsDesc, propsDescc, propsImg}) => {


  return (
    <div className={classes.structure1_parent} style={propsParent}>
        <div className={classes.structure1_title} style={propsTitle}>
          {title.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
              {/* {title} */}
        </div>
        <h3 className={classes.descc} style={propsDescc}>
              {descc}
        </h3>
        <p className={classes.structure1_desc} style={propsDesc}>
          {/* {desc.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} */}
              {desc}
        </p>
        <InternationalBtn title={btn} />
        <div className={classes.structure1_image} style={propsImg}>
            {/* <img src={image} alt="image" /> */}
            {type==='video'? (
              <video width="100%" autoPlay loop muted>
                <source src={videos} type="video/mp4" />
              </video>
            ):(
              <img src={image} alt="image" wdith="100%" />
            )}
            {/* <video width="100%" controls>
              <source src='https://rival.finance/wp-content/uploads/2024/01/CoinsAnimation730310.mp4' type="video/mp4" />
            </video> */}
        </div>
    </div>
  )
}

export default Structure1;